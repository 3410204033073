<template>
  <div class="upload">
    <mediaviewer :media="item" width="1024" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Event',
  data: function() {
    return {
    };
  },
  props: {
    item: Object,
    resource: String
  },
  computed: {
    ...mapGetters(['isLoading', 'permissions']),
  },

  methods: {
    
  },
}
</script>

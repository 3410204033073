<template>
  <div class="event">
    <div id="top">
      <h1>{{ item.title }}</h1>
      <div>
        <b-button
          class="mr-2"
          v-if="permissions('read', resource) && resource == 'event'"
          variant="success btn-icon"
          :to="`/event/${item.id}/manage`"
          ><font-awesome-icon :icon="['far', 'arrow-right']"
        /></b-button>
        <b-button variant="primary" @click="$emit('save')"
          ><font-awesome-icon :icon="['far', 'save']" />Save</b-button
        >
      </div>
    </div>
    <div v-if="item">
      <b-alert :show="item.status == 'active'" variant="danger">
        WARNING! This event has already started. Changes to this Event are not
        immediately visible for players already connected to this event.
        Changing the start date/time also has no effect.
      </b-alert>
      <b-row>
        <b-col sm="6">
          <b-form-group label="Title">
            <b-form-input
              v-model="item.title"
              :disabled="isLoading"
              type="text"
              placeholder=""
              name="event-title"
            />
          </b-form-group>

          <b-form-group label="Description">
            <wysiwyg v-model="item.description" />
          </b-form-group>

          <b-form-group label="Code">
            <b-form-input
              v-model="item.code"
              :disabled="isLoading || item.status == 'active'"
              type="text"
              placeholder=""
              name="event-code"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group label="Game">
            <!-- TODO: Select van games -->
            <b-form-input
              v-if="item.Game && item.Game.title"
              v-model="item.Game.title"
              disabled
              type="text"
              placeholder=""
              name="event-game"
            />
          </b-form-group>

          <b-form-group label="Reseller">
            <!-- TODO: Select van reseller als je (super)admin bent -->
            <b-form-input
              v-if="item.Game && item.Game.title"
              v-model="item.Reseller.title"
              disabled
              type="text"
              placeholder=""
              name="event-reseller"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <!-- Options with generic component -->
          <h3>Overwrite game options</h3>
          <template v-for="key in Object.keys(options)">
            <optioncolumninput
              :disabled="
                options[key].key == 'game_type' ||
                options[key].key == 'nav_type' ||
                item.status == 'active'
              "
              :key="'event-game-option-' + key"
              :option="options[key]"
              :item="item"
            />
          </template>

          <!-- Options with generic component -->
          <!-- <optioninput v-for="key in Object.keys(eventOptions)" :key="'eventoption-' + key" :option="eventOptions[key]" :item="item" /> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  name: "Event",
  data: function () {
    return {};
  },
  props: {
    item: Object,
    resource: String,
  },
  computed: {
    ...mapState(["eventOptions", "gameOptions"]),
    ...mapGetters(["isLoading", "permissions"]),

    options() {
      let options = this.gameOptions.concat(this.eventOptions);
      options.sort((a, b) => {
        if (a.toggle) {
          if (a.toggle == b.key) return 0;
          else return -1;
        }
        return 0;
      });
      return options;
    },
  },

  methods: {},
};
</script>

<template>
  <div class="home">
    <navigation />
    <h1>Home</h1>
    <template v-if="stats && permissions('create', 'reseller')">
      <b-row>
        <b-col sm="4" md="6">
          <b-card>
            <b-card-title>Server Stats</b-card-title>
            <b-card-body>
              <b-alert
                :variant="alertVariant(memFree, memTotal, 10)"
                :show="true"
              >
                <b-row>
                  <b-col sm="12"
                    ><strong
                      >Memory {{ percentage(memFree, memTotal) }}%
                      available</strong
                    ></b-col
                  >

                  <b-col sm="4">Total</b-col>
                  <b-col sm="4">Used</b-col>
                  <b-col sm="4">Free</b-col>

                  <b-col sm="4">{{ memTotal }}GB</b-col>
                  <b-col sm="4">{{ memUsed }}GB</b-col>
                  <b-col sm="4">{{ memFree }}GB</b-col>
                </b-row>
              </b-alert>

              <b-alert
                :variant="alertVariant(diskFree, diskTotal, 10)"
                :show="true"
              >
                <b-row>
                  <b-col sm="12"
                    ><strong
                      >Storage {{ percentage(diskFree, diskTotal) }}%
                      available</strong
                    ></b-col
                  >

                  <b-col sm="4">Total</b-col>
                  <b-col sm="4">Used</b-col>
                  <b-col sm="4">Free</b-col>

                  <b-col sm="4">{{ diskTotal }}GB</b-col>
                  <b-col sm="4">{{ diskUsed }}GB</b-col>
                  <b-col sm="4">{{ diskFree }}GB</b-col>
                </b-row>
              </b-alert>
            </b-card-body>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <b-card-title>Event Stats</b-card-title>
            <b-card-body>
              <strong>Active Events: {{ stats.rooms.length }}</strong
              ><br />
              <em>Events with at least 1 connected client</em><br />
              <strong>Joinable Events: {{ stats.activeEvents }}</strong
              ><br />
              <strong>Pending Events: {{ stats.pendingEvents }}</strong
              ><br />
              <strong>Ended Events: {{ stats.endedEvents }}</strong
              ><br />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  data: function () {
    return {
      stats: null,
    };
  },
  components: {},
  computed: {
    ...mapGetters(["isLoading", "permissions"]),
    memFree() {
      if (this.stats) {
        return (
          Math.round(
            (this.stats.server.mem.available / 1024 / 1024 / 1024) * 100
          ) / 100
        );
      }
    },
    memTotal() {
      if (this.stats) {
        return (
          Math.round((this.stats.server.mem.total / 1024 / 1024 / 1024) * 100) /
          100
        );
      }
    },
    memUsed() {
      if (this.stats) {
        return (
          Math.round(
            (this.stats.server.mem.active / 1024 / 1024 / 1024) * 100
          ) / 100
        );
      }
    },
    diskFree() {
      if (this.stats) {
        return (
          Math.round(
            (this.stats.server.disk.available / 1024 / 1024 / 1024) * 100
          ) / 100
        );
      }
    },
    diskTotal() {
      if (this.stats) {
        return (
          Math.round((this.stats.server.disk.size / 1024 / 1024 / 1024) * 100) /
          100
        );
      }
    },
    diskUsed() {
      if (this.stats) {
        return (
          Math.round((this.stats.server.disk.used / 1024 / 1024 / 1024) * 100) /
          100
        );
      }
    },
  },
  methods: {
    percentage(free, total) {
      return Math.round((free / total) * 100);
    },
    alertVariant(free, total, danger = 25) {
      if (this.percentage(free, total) < danger) return "danger";
      return "primary";
    },
  },
  mounted() {
    axios.get(`/api/stats`).then((res) => (this.stats = res.data));
  },
};
</script>

"use strict";

import Vue from 'vue';
import axios from "axios";
import store from '../store'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// let config = {
//   // baseURL: process.env.baseURL || process.env.apiUrl || ""
//   // timeout: 60 * 1000, // Timeout
//   // withCredentials: true, // Check cross-site Access-Control
// };

// const axios = axios.create(config);

axios.interceptors.request.use(
  function(config) {
    //increase num active requests
    store.commit('numPendingRequests', store.state.numPendingRequests + 1)

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function(response) {
    //decrease num active requests
    store.commit('numPendingRequests', store.state.numPendingRequests - 1)

    return response;
  },
  function(error) {
    //decrease num active requests
    store.commit('numPendingRequests', store.state.numPendingRequests - 1)

    return Promise.reject(error);
  }
);

let AxiosPlugin = {}

AxiosPlugin.install = function(Vue) {
  Vue.axios = axios;
  window.axios = axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return axios;
      }
    },
    $axios: {
      get() {
        return axios;
      }
    },
  });
};

Vue.use(AxiosPlugin)

export default AxiosPlugin

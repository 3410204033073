<template>
  <div>
    <b-table v-if="items.length > 0" :fields="fields" striped :items="items">
                        
      <template #cell(playerId)="data">
        <template v-if="player_by_id(data.item.playerId)">
          {{ player_by_id(data.item.playerId).name }}
        </template>
      </template>

      <template #cell(taskId)="data">
        {{ task_by_id(data.item.taskId).title }}
      </template>

      <template #cell(points)="data">
        <b-input v-model="data.item.points" class="input-points" />
      </template>

      <template #cell(answer)="data">
        <mediaviewer v-if="data.item.Media" :media="data.item.Media" width="200" />
        <template v-else>{{ data.item.answer }}</template>
      </template>

      <template #cell(actions)="data">
        <b-button-group v-if="data.item.status == 'pending'">
          <b-button variant="success btn-icon" @click="set_task_status(data.item, 'approved')"><font-awesome-icon :icon="['far', 'check']"/></b-button>
          <b-button variant="danger btn-icon" @click="set_task_status(data.item, 'denied')"><font-awesome-icon :icon="['far', 'times']"/></b-button>
        </b-button-group>

        <b-button variant="danger btn-icon" @click="delete_task_answer(data.item)"><font-awesome-icon :icon="['far', 'trash']"/></b-button>

      </template>
    </b-table>

    <b-alert variant="info" show>Er zijn (nog) geen antwoorden gevonden</b-alert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'AnswersTable',
  data: function() {
    return {
      visible: true,

      fields: [
        { key: 'playerId', label: 'Player' },
        { key: 'taskId', label: 'Task' },
        { key: 'answer' },
        { key: 'points' },
        { key: 'actions' }
      ]
    };
  },
  props: {
    event: Object,
    items: Array
  },
  computed: {
    ...mapGetters(['isLoading', 'permissions']),
    ...mapGetters('socket', ['player_by_id', 'task_by_id']),
  },
  methods: {
    set_task_status(answer, status) {
      answer.status = status
      this.$store.dispatch('socket/update_answer', answer)
    },
    delete_task_answer(answer) {
      this.$store.dispatch('socket/delete_answer', answer)
    }
  },
}
</script>
<template>
  <div class="game">
    <b-container fluid>
      <div id="top">
        <h1>{{ item.title }}</h1>
        <b-button variant="primary" @click="trySave"
          ><font-awesome-icon :icon="['far', 'save']" />Save</b-button
        >
      </div>
      <div v-if="item">
        <b-row>
          <b-col sm="6">
            <b-form-group label="Title">
              <b-form-input
                v-model="item.title"
                :disabled="
                  isLoading ||
                  (item.duplicate && !permissions('create', resource))
                "
                type="text"
                placeholder=""
                required
                name="game-title"
              />
            </b-form-group>

            <b-form-group label="SubTitle">
              <b-form-input
                v-model="item.subtitle"
                :disabled="isLoading"
                type="text"
                placeholder=""
                name="game-title"
              />
            </b-form-group>

            <mediaupload
              key="GameLogo"
              :upload="item.Logo"
              field="logoId"
              label="Logo"
              accept="image/jpeg, image/png"
              :item="item"
              @change="$emit('save')"
            />

            <b-form-group
              label="Description"
              description="Content to show once after loading the event."
            >
              <wysiwyg v-model="item.description" />
            </b-form-group>

            <b-form-group
              label="Help"
              description="Content to show in the 'help' tab of the event."
            >
              <wysiwyg v-model="item.help" />
            </b-form-group>

            <b-form-group label="Skin">
              <!-- <b-form-input v-model="item.skin" ></b-form-input> -->
              <b-form-select
                v-model="item.skin"
                :options="gameSkins"
              ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <!-- Options with generic component -->
            <optioncolumninput
              v-for="key in Object.keys(gameOptions)"
              :key="'gameoption-' + key"
              :option="gameOptions[key]"
              :item="item"
            />
          </b-col>
        </b-row>

        <div id="rounds">
          <h2>Rounds</h2>
          <!-- Round card -->
          <draggable
            v-model="item.Rounds"
            group="rounds"
            @end="updateRoundSorting"
            handle=".round-drag"
          >
            <b-card
              v-for="round in item.Rounds"
              :key="`card-round-${round.id}`"
              :ref="`card-round-${round.id}`"
              :class="{ invalid: hasInvalidChild(`card-round-${round.id}`) }"
            >
              <b-card-title
                :class="{
                  collapsed: !collapseRounds[round.id],
                  'round-drag': true,
                }"
                @click="toggleRound(round.id)"
                >{{ round.title }}</b-card-title
              >

              <b-card-body
                :style="{
                  display: collapseRounds[round.id] ? 'block' : 'none',
                }"
                :id="`card-collapse-round-${round.id}`"
              >
                <b-row>
                  <b-col md="4">
                    <b-form-group label="Title">
                      <b-form-input
                        :ref="`round-${round.id}-title`"
                        v-model="round.title"
                        :disabled="isLoading"
                        type="text"
                        placeholder=""
                        required
                        name="round-title"
                      />
                    </b-form-group>

                    <!-- DISABLED FOR NOW, THIS DATA IS NOT USED AT THE MOMENT! -->
                    <!-- <mediaupload
                      :key="`RoundMedia${round.id}`"
                      :upload="round.Media"
                      field="mediaId"
                      label="Media"
                      :item="round"
                      @change="$emit('save')"
                    />

                    <b-form-group label="Description">
                      <wysiwyg v-model="round.description" />
                    </b-form-group> -->

                    <optioninput
                      v-for="key in Object.keys(roundOptions)"
                      :key="'roundoption-' + key"
                      :option="roundOptions[key]"
                      :item="round"
                    />
                  </b-col>

                  <b-col v-if="round.Tasks">
                    <draggable
                      v-model="round.Tasks"
                      group="tasks"
                      @end="updateRoundSorting"
                      handle=".task-drag"
                    >
                      <!-- Task card -->
                      <b-card
                        v-for="task in round.Tasks"
                        :key="`card-task-${task.id}`"
                        :ref="`card-task-${task.id}`"
                        :class="{
                          invalid: hasInvalidChild(`card-task-${task.id}`),
                        }"
                      >
                        <b-card-title
                          :class="{
                            collapsed: !collapseTasks[round.id],
                            'task-drag': true,
                          }"
                          @click="toggleTask(task.id)"
                        >
                          {{ task.title }}
                          <b-badge
                            class="ml-2"
                            v-if="task.Option.find((o) => o.key == 'type')"
                            ><small>{{
                              task.Option.find((o) => o.key == "type").value
                            }}</small></b-badge
                          >
                          <b-badge
                            class="ml-2"
                            v-if="
                              task.Option.find((o) => o.key == 'start_type')
                            "
                            ><small>{{
                              task.Option.find((o) => o.key == "start_type")
                                .value
                            }}</small></b-badge
                          >
                        </b-card-title>

                        <b-card-body
                          :style="{
                            display: collapseTasks[task.id] ? 'block' : 'none',
                          }"
                          :id="`card-collapse-task-${task.id}`"
                        >
                          <b-form-group label="Title">
                            <b-form-input
                              :ref="`task-${task.id}-title`"
                              v-model="task.title"
                              :disabled="isLoading"
                              type="text"
                              placeholder=""
                              required
                              name="task-title"
                            />
                          </b-form-group>

                          <b-form-group label="Task">
                            <b-form-input
                              v-model="task.task"
                              :disabled="isLoading"
                              type="text"
                              placeholder=""
                              required
                              name="task-task"
                            />
                          </b-form-group>

                          <mediaupload
                            :key="`TaskMedia${task.id}`"
                            :upload="task.Media"
                            field="mediaId"
                            label="Media"
                            :item="task"
                            @change="$emit('save')"
                          />

                          <b-form-group label="Description">
                            <wysiwyg v-model="task.description" />
                          </b-form-group>

                          <b-form-group label="Game master Instructions">
                            <wysiwyg v-model="task.manager_instructions" />
                          </b-form-group>

                          <optioninput
                            v-for="key in Object.keys(taskOptions)"
                            :key="'taskoption-' + key"
                            :option="taskOptions[key]"
                            :item="task"
                          />

                          <div class="buttons">
                            <b-button
                              v-if="permissions('update', resource)"
                              variant="link danger"
                              @click="deleteTask(round.id, task.id)"
                              :disabled="isLoading"
                              >Delete task
                              <font-awesome-icon :icon="['far', 'trash']"
                            /></b-button>
                          </div>
                        </b-card-body>
                      </b-card>
                    </draggable>
                  </b-col>

                  <b-col cols="12" class="buttons">
                    <b-button
                      v-if="permissions('update', resource)"
                      variant="link danger"
                      @click="deleteRound(round.id)"
                      :disabled="isLoading"
                      ><font-awesome-icon :icon="['far', 'trash']" />Delete
                      round</b-button
                    >
                    <b-button
                      v-if="permissions('update', resource)"
                      variant="primary"
                      @click="createTask(round.id)"
                      :disabled="isLoading"
                      ><font-awesome-icon :icon="['far', 'plus']" /> Add
                      task</b-button
                    >
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </draggable>
          <b-button
            v-if="permissions('update', resource)"
            variant="primary"
            @click="createRound"
            :disabled="isLoading"
            ><font-awesome-icon :icon="['far', 'plus']" />Add new
            round</b-button
          >
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "Game",
  data: function () {
    return {
      changed: false,
      collapseRounds: [],
      collapseTasks: [],
      roundFields: [
        { key: "id", label: "#" },
        { key: "sort", label: "Sort", sortable: true },
        { key: "name", label: "Name" },
        { key: "description", label: "Description" },
        { key: "Option", label: "Options" },
        { key: "actions", label: "Actions" },
      ],
      taskFields: [
        { key: "id", label: "#" },
        { key: "sort", label: "Sort", sortable: true },
        { key: "name", label: "Name" },
        { key: "description", label: "Description" },
        { key: "Option", label: "Options" },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  props: {
    item: Object,
    resource: String,
  },
  computed: {
    ...mapState(["gameOptions", "roundOptions", "taskOptions", "gameSkins"]),
    ...mapGetters(["isLoading", "permissions"]),
  },
  watch: {
    item() {
      this.$nextTick(() => {
        this.item.Rounds.sort((a, b) => (a.sort > b.sort ? 1 : -1));
        for (const round of this.item.Rounds) {
          round.Tasks.sort((a, b) => (a.sort > b.sort ? 1 : -1));
        }
      });
    },
  },
  methods: {
    hasInvalidChild(ref) {
      if (this.$refs[ref] && this.$refs[ref][0]) {
        let invalid = this.$refs[ref][0].querySelectorAll(":invalid");
        return invalid.length > 0;
      }
      return false;
    },

    deleteRound(roundId) {
      if (
        confirm(
          `Are you sure you want to delete round ${roundId} and all of it's tasks?`
        )
      ) {
        this.$store
          .dispatch(`${this.resource}/update`, this.$route.params.id)
          .then(() => {
            this.$store.dispatch(`${this.resource}/delete_round`, {
              gameId: this.$route.params.id,
              roundId: roundId,
            });
          });
      }
    },
    async createRound() {
      this.$store
        .dispatch(`${this.resource}/update`, this.$route.params.id)
        .then(() => {
          let sort = this.item.Rounds ? this.item.Rounds.length : 0;
          let round = { title: `Round ${sort + 1}`, sort: sort };

          this.$store
            .dispatch(`${this.resource}/create_round`, {
              gameId: this.$route.params.id,
              round: round,
            })
            .then((res) => {
              let id = res.Rounds[res.Rounds.length - 1].id;
              Vue.set(this.collapseRounds, id, true);
              Vue.nextTick().then(() => {
                this.$refs[`round-${id}-title`][0].$el.focus();
              });
            });
        });
    },

    deleteTask(roundId, taskId) {
      if (confirm(`Are you sure you want to delete task ${taskId}?`)) {
        this.$store
          .dispatch(`${this.resource}/update`, this.$route.params.id)
          .then(() => {
            this.$store.dispatch(`${this.resource}/delete_task`, {
              gameId: this.$route.params.id,
              roundId: roundId,
              taskId: taskId,
            });
          });
      }
    },
    createTask(roundId) {
      this.$store
        .dispatch(`${this.resource}/update`, this.$route.params.id)
        .then(() => {
          //get round
          let round = this.item.Rounds.find((r) => r.id == roundId);
          let sort = round && round.Tasks ? round.Tasks.length : 0;

          let task = {
            title: `Task ${sort + 1}`,
            task: `Task ${sort + 1}`,
            sort: sort,
          };

          this.$store
            .dispatch(`${this.resource}/create_task`, {
              gameId: this.$route.params.id,
              roundId: roundId,
              task: task,
            })
            .then((res) => {
              round = res.Rounds.find((r) => r.id == roundId);
              let id = round.Tasks[round.Tasks.length - 1].id;
              // Vue.set(this.collapseTasks, id, true)
              this.collapseTasks = [];
              Vue.nextTick().then(() => {
                this.$refs[`task-${id}-title`][0].$el.focus();
              });
            });
        });
    },

    toggleRound(roundId) {
      Vue.set(this.collapseRounds, roundId, !this.collapseRounds[roundId]);
    },
    toggleTask(taskId) {
      Vue.set(this.collapseTasks, taskId, !this.collapseTasks[taskId]);

      //dispatch resize so the Leaflet can get the correct size
      this.$nextTick(() => {
        window.dispatchEvent(new Event("resize"));
      });
    },
    updateRoundSorting() {
      for (const round of this.item.Rounds) {
        round.sort = this.item.Rounds.findIndex((r) => r.id == round.id);

        for (const task of round.Tasks) {
          task.sort = round.Tasks.findIndex((t) => t.id == task.id);
        }
      }
      this.trySave();
    },

    trySave() {
      let r = this.collapseRounds;
      let t = this.collapseTasks;

      this.collapseRounds = this.item.Rounds.map((r) => r.id);
      this.collapseTasks = this.item.Rounds.map((r) =>
        r.Tasks.map((t) => t.id)
      ).flat();

      this.$nextTick(() => {
        this.$emit("save");

        this.collapseRounds = r;
        this.collapseTasks = t;
      });
    },
  },
  components: { draggable },
};
</script>

import Vue from 'vue'
import axios from 'axios'
import { getTimeDiff } from '../util'

let moduleState = () => {
  return {
    connecting: false,
    code: "",
    event: null,
    game: null,
    answers: [],
    playerId: null,
    players: [],
    teams: [],
    chat: [],
    unread_chat_messages: false,
    unread_teamchat_messages: [],
    highscores: [],
    location: null
  }
}
let moduleGetters = {
  player: state => state.players.find((p) => p.id == state.playerId) || JSON.parse(JSON.stringify({ name: "" })),
  team: (state, getters) => state.teams.find((t) => t.TeamPlayers.some(tp => tp.playerId == getters.player.id)) || JSON.parse(JSON.stringify({ name: "" })),
  role: (state, getters) => getters.team_by_player_id(state.playerId).TeamPlayers.find((p) => p.playerId == state.playerId).role,
  can_answer: (state, getters) => (
    !state.event.teams ||
    (state.event.teams && state.event.team_type == 'default' && getters.have_team && getters.role == 'captain') ||

    //Hotseat games anyone can answer
    (state.game && state.event.teams && state.event.team_type == 'hotseat' && getters.have_team)
  ),
  player_index_by_player_id: (state) => {
    return state.players.reduce((map, player, index) => {
      map[player.id] = index
      return map
    }, {})
  },
  player_by_id: (state, getters) => (id) => {
    return state.players[getters.player_index_by_player_id[id]]
  },

  team_index_by_team_id: (state) => {
    return state.teams.reduce((map, team, index) => {
      map[team.id] = index
      return map
    }, {})
  },
  team_by_id: (state, getters) => (id) => {
    return state.teams[getters.team_index_by_team_id[id]]
  },

  has_seen_intro: (state) => {
    if (state.event && state.event.Data) {
      let introData = state.event.Data.find((o) => o.key == `seen_intro`)
      if (introData) {
        return Array.isArray(introData.value) && introData.value.includes(state.playerId)
      }
    }
    return false
  },

  event_started: (state) => {
    if (state.event) {
      return state.event.status == 'active'
    }
    return false;
  },
  event_ended: (state) => {
    if (state.event) {
      return state.event.status == 'ended'
    }
    return false;
  },
  event_time_elapsed: (state) => () => {
    let start_time = new Date(state.event.start_date)
    let passedTime = getTimeDiff(start_time)

    return passedTime.seconds
  },
  event_time_remaining: (state, getters) => () => {
    let duration_in_seconds = state.event.duration * 60
    return duration_in_seconds - getters.event_time_elapsed()
  },

  ///GPS
  gps_rounds: (state) => {
    var rounds = []
    if (state.game && state.game.Rounds) {
      rounds = state.game.Rounds.filter(r => r.Option.filter(o => o.key == 'start_type' && o.value == 'gps').length == 1)
    }
    return rounds
  },
  gps_tasks: (state, getters) => {
    var tasks = []
    if (state.game && state.game.Rounds) {
      tasks = state.game.Rounds.filter(r => getters.round_available(r.id)).map(r => r.Tasks.filter(t => t.Option.filter(o => o.key == 'start_type' && o.value == 'gps').length == 1).map(t => t)).flat()
    }
    return tasks
  },
  event_uses_gps: (state, getters) => {
    return getters.gps_tasks.length > 0 || getters.gps_rounds.length > 0
  },
  playerLatLngs(state, getters) {
    let playerIds = [];
    if (!state.event.teams) {
      playerIds = state.players.map((player) => {
        return player.id;
      });
    } else if (
      state.event &&
      state.event.teams &&
      state.teams &&
      Array.isArray(state.teams)
    ) {
      playerIds = state.teams
        .map((t) => t.TeamPlayers)
        .flat()
        .map((player) => {
          return player.playerId;
        });
    }

    let eventPlayers = playerIds
      .filter((p) => {
        const player = getters.player_by_id(p);
        return player.EventPlayer != null;
      })
      .map((p) => {
        const player = getters.player_by_id(p);
        return player.EventPlayer;
      });
    return eventPlayers.filter((ep) => {
      return ep && ep.latitude != null && ep.longitude != null;
    });
  },


  //check is current player is in a team
  have_team: (state) => {
    if (state.event.teams && state.teams) {
      return state.teams.map((t) => t.TeamPlayers.map((p) => p.playerId)).flat().includes(parseInt(state.playerId))
    }
    return false
  },
  team_by_player_id: (state) => (playerId) => {
    return state.teams.find((t) => t.TeamPlayers.find((p) => p.playerId == playerId))
  },
  //check if a player is in a team (specify playerId)
  team_player_ids: (state) => {
    return state.teams.map((t) => t.TeamPlayers.map((p) => p.playerId)).flat()
  },
  player_has_team: (state, getters) => (playerId) => {
    if (state.event.teams && state.teams) {
      return getters.team_player_ids.includes(parseInt(playerId))
    }
    return false
  },

  player_badges: (state) => playerId => {
    if (!state.event || !state.event.Extra) return []
    return state.event.Extra.filter(e => e.type == 'badge' && e.playerId == playerId)
  },

  round_available: (state, getters) => (roundId) => {
    let available = false

    if (state.event && state.event.Data) {
      let startedData = state.event.Data.find((o) => o.key == `round_${roundId}_started`)

      if (startedData) {
        if (typeof startedData.value == "boolean")
          available = startedData.value
        else if (Array.isArray(startedData.value)) {
          let unlockId = (state.event.teams && getters.team_by_player_id(state.playerId)) ? getters.team_by_player_id(state.playerId).id : state.playerId
          available = startedData.value.includes(unlockId)
        }
      }
    }

    return available
  },
  round_hidden: (state) => (roundId) => {
    let hidden = false

    if (state.event && state.event.Data) {
      let hiddenData = state.event.Data.find((o) => o.key == `round_${roundId}_hidden`)
      if (hiddenData) {
        hidden = hiddenData.value
      }
    }

    return hidden
  },
  is_round_complete: (state, getters) => (roundId) => {
    let round = getters.round_by_id(roundId)
    let completed = round.Tasks.reduce((prev, cur) => getters.is_task_complete(cur.id) ? prev + 1 : prev, 0)
    return completed == round.Tasks.length
  },

  task_available: (state, getters) => (taskId) => {
    let available = false

    if (state.event && state.event.Data) {
      let startedData = state.event.Data.find((o) => o.key == `task_${taskId}_started`)

      if (startedData) {
        if (typeof startedData.value == "boolean")
          available = startedData.value
        else if (Array.isArray(startedData.value)) {
          let unlockId = (state.event.teams && getters.team_by_player_id(state.playerId)) ? getters.team_by_player_id(state.playerId).id : state.playerId
          available = startedData.value.includes(unlockId)
        }
      }
    }

    return available
  },
  task_hidden: (state) => (taskId) => {
    let hidden = false

    if (state.event && state.event.Data) {
      let hiddenData = state.event.Data.find((o) => o.key == `task_${taskId}_hidden`)
      if (hiddenData) {
        hidden = hiddenData.value
      }
    }

    return hidden
  },

  round_by_id: (state) => (id) => {
    if (state.game && state.game.Rounds) {
      return state.game.Rounds.find((r) => r.id == id)
    }
    return null
  },
  task_by_id: (state) => (id) => {
    if (state.game && state.game.Rounds) {
      return state.game.Rounds.map((r) => r.Tasks.map((t) => t)).flat().find((t) => t.id == id)    //map all rounds + tasks and flatten them into one array so we can use it in the where-clause
    }
    return null
  },

  answer_by_id: (state) => (id) => {
    if (state.answers) {
      return state.answers.find((a) => a.id == id)
    }
    return null
  },
  answers_by_task_id: (state) => (taskId) => {
    return state.answers.filter((a) => a.taskId == taskId)
  },

  // antwoord goedgekeurd OF aantal pogingen op
  is_task_complete: (state, getters) => (taskId) => {
    let answers = getters.answers_by_task_id(taskId)
    return answers.reduce((complete, answer) => answer.status == 'approved' || complete, false) || (getters.task_max_tries(taskId) > 0 && answers.length >= getters.task_max_tries(taskId))
  },
  // nog een antwoord niet beoordeeld
  is_task_pending: (state, getters) => (taskId) => {
    let answers = getters.answers_by_task_id(taskId)
    return answers.reduce((complete, answer) => answer.status == 'pending' || complete, false)
  },

  // aantal pogingen op en geen enkel antwoord goedgekeurd
  is_task_denied: (state, getters) => (taskId) => {
    if (getters.task_num_tries(taskId) == getters.task_max_tries(taskId)) {
      let answers = getters.answers_by_task_id(taskId)
      return answers.every((answer) => answer.status == 'denied')
    }
    return false
  },
  // een antwoord goedgekeurd
  is_task_approved: (state, getters) => (taskId) => {
    let answers = getters.answers_by_task_id(taskId)
    return answers.some((answer) => answer.status == 'approved')
  },
  task_num_tries: (state, getters) => (taskId, playerId) => {
    if (!playerId) {
      playerId = getters.player.id
    }

    var playerIds = [playerId]
    if (state.event.teams) {
      playerIds = getters.team_by_player_id(playerId).TeamPlayers.map(tp => tp.playerId)
    }

    return getters.answers_by_task_id(taskId).filter(a => playerIds.includes(a.playerId)).length
  },
  task_max_tries: (state, getters) => (taskId) => {
    let tries = getters.task_by_id(taskId).Option.find(o => o.key == 'tries')
    if (tries && tries.value) return tries.value
    return 0
  },

  //returns the current task that isn't completed yet, used to auto navigate in chronological games
  active_task: (state, getters) => {
    if (state.game && state.game.Rounds) {
      let tasks = state.game.Rounds.map(r => r.Tasks.map(t => t)).flat()
      return tasks.find(t => !getters.is_task_complete(t.id))
    }
    return null
  },
  //returns the current round that isn't completed yet, used to auto navigate in chronological games
  active_round: (state, getters) => {
    if (state.game && state.game.Rounds) {
      return state.game.Rounds.find(t => !getters.is_round_complete(t.id))
    }
    return null
  },
  event_home_url: (state, getters) => {
    let url = `/event/rounds`
    if (state.game && state.game.nav_type) {
      if (getters.active_round != null && getters.active_task != null) {
        switch (state.game.nav_type) {
          case 'round':
            url = url + `/${getters.active_round.id}`
            break
          case 'task':
            url = url + `/${getters.active_round.id}/tasks/${getters.active_task.id}`
            break

          case 'none':
          default:
            break
        }
      } else {
        url = `/gameoutro`
      }
    }
    return url
  },

  team_chat(state, getters) {
    return state.chat.filter(c => c.teamId == getters.team.id)//.splice(-10)
  },
  event_chat(state) {
    return state.chat.filter(c => c.teamId == null)//.splice(-10)
  }
}
let moduleMutations = {
  connecting(state, val) {
    state.connecting = val
  },
  code(state, val) {
    state.code = val
  },
  event(state, val) {
    state.event = Object.assign(state.event || {}, val)
  },
  game(state, val) {
    state.game = val
  },
  playerId(state, val) {
    state.playerId = val
  },
  players(state, val) {
    state.players = val
  },
  event_player(state, val) {
    // if (val && Array.isArray(val)) {
    //   val.forEach(ep => {
    //     if (ep && ep.playerId) {
    //       const idx = state.players.findIndex((p) => p.id == ep.playerId)
    //       if (idx > -1) {
    //         Vue.set(state.players[idx], 'EventPlayer', ep)
    //       }
    //     }
    //   })
    // } else if (val && val.playerId) {
    //   const idx = state.players.findIndex((p) => p.id == val.playerId)
    //   if (idx > -1) {
    //     Vue.set(state.players[idx], 'EventPlayer', val)
    //   }
    // }
  },
  teams(state, val) {
    state.teams = val
    // if (state.event) {
    //   Vue.set(state.event, 'Teams', val)
    // }
  },
  data(state, val) {
    if (Array.isArray(val)) {
      Vue.set(state.event, 'Data', val)
    } else {
      if (!Array.isArray(state.event.Data)) {
        Vue.set(state.event, 'Data', [])
      }

      let idx = state.event.Data.findIndex((d) => d.id == val.id)
      if (idx >= 0) {
        Vue.set(state.event.Data, idx, val)
      } else {
        state.event.Data.push(val)
      }
    }
    state.event = Object.assign({}, state.event)
  },
  extra(state, val) {
    if (Array.isArray(val)) {
      Vue.set(state.event, 'Extra', val)
    } else {
      if (!Array.isArray(state.event.Extra)) {
        Vue.set(state.event, 'Extra', [])
      }

      let idx = state.event.Extra.findIndex((d) => d.id == val.id)
      if (idx >= 0) {
        Vue.set(state.event.Extra, idx, val)
      } else {
        state.event.Extra.push(val)
      }
    }
    state.event = Object.assign({}, state.event)
  },
  answers(state, val) {
    state.answers = val
  },
  chat(state, val) {
    state.chat = val
  },
  chat_message(state, val) {
    state.chat.push(val)
  },
  chat_messages(state, val) {
    state.chat = val
  },
  unread_chat_messages(state, val) {
    state.unread_chat_messages = val
  },
  unread_teamchat_messages(state, val) {
    state.unread_teamchat_messages = val;
  },
  highscores(state, val) {
    state.highscores = val

    if (state.highscores) state.highscores.sort((a, b) => {
      if (a.score > b.score) return -1
      else if (a.score < b.score) return 1
      else return 0
    })
  },
  location(state, val) {
    state.location = val
  }
}
let moduleActions = {

  init({ state, dispatch }) {
    if (state.code != "") {
      dispatch('join')
    }
  },
  async tick() {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('tick', {})
    }
  },
  /// ACTIONS FROM CLIENT -> SERVER
  async join({ commit, state }) {
    //do nothing if already connecting
    if (state.connecting) return;

    //disconnect first
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.disconnect()
    }

    let query = {}
    if (state.code) {
      query.code = state.code
    }
    if (state.playerId) {
      query.uid = state.playerId
    }

    this._vm.$socket.client.auth = query
    this._vm.$socket.client.connect()

    commit('connecting', true)
  },
  async leave({ commit }) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.disconnect()
    }
    this._vm.$socket.client.auth = {}

    commit('code', null)
    commit('event', null)
    commit('players', [])
    commit('teams', [])
    commit('chat', [])
  },
  async save_player({ getters }) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('update_player', getters.player)
    }
  },
  async create_player(_, player) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('create_player', player)
    }
  },
  async create_team(_, team) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('create_team', team)
    }
  },
  async create_hotseat_team(_, team) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('create_hotseat_team', team)
    }
  },
  async join_team(_, team) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('join_team', team)
    }
  },
  async leave_team({ getters }) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('leave_team', getters.team)
    }
  },
  async submit_answer({ getters }, answer) {
    if (this._vm.$socket.connected && getters.can_answer) {
      this._vm.$socket.client.emit('submit_answer', answer)
    }
  },
  async set_intro_complete() {
    if (this._vm.$socket.client.connected) {
      this._vm.$socket.client.emit('set_intro_complete')
    }
  },
  async update_location({ commit }, location) {
    commit('location', location)

    if (this._vm.$socket.client.connected && location && location.coords) {
      this._vm.$socket.client.emit('location', { lat: location.coords.latitude, lng: location.coords.longitude, accuracy: location.coords.accuracy })
    }
  },

  async update_answer(_, answer) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('update_answer', answer)
    }
  },
  async delete_answer(_, answer) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('delete_answer', answer)
    }
  },
  async update_team_player(_, teamplayer) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('update_team_player', teamplayer)
    }
  },
  async update_game() {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('update_game')
    }
  },

  async create_media({ state }, data) {
    let result = null

    await axios.post(`/api/event/${state.code}/upload`, data)
      .then((response) => {
        result = response.data
      })
      .catch((err) => { console.log(err) })

    return result
  },

  /// ACTIONS TRIGGERED FROM SERVER

  /// EVENT ACTIONS
  async socket_invalidEvent({ commit }) {
    commit('event', null)
    commit('connecting', false)
  },
  async socket_event({ commit }, event) {
    commit('event', event)
    commit('connecting', false)
  },
  async socket_playerId({ commit }, playerId) {
    //get current auth
    let query = this._vm.$socket.client.auth
    //set playerId
    query.uid = playerId

    //set socket playerId
    this._vm.$socket.client.auth = query

    //store playerId in store
    commit('playerId', playerId)
  },
  async socket_eventData({ commit }, data) {
    commit('data', data)
  },
  async socket_eventExtra({ commit }, data) {
    commit('extra', data)
  },
  async socket_eventExtraRemoved({ state }, extra) {
    if (state.event && state.event.Extra && Array.isArray(state.event.Extra)) {
      let idx = state.event.Extra.findIndex(e => e.id == extra.id)
      if (idx > -1) {
        state.event.Extra.splice(idx, 1)
      }
    }
  },
  async socket_game({ commit }, game) {
    commit('game', game)
  },
  async socket_highscores({ commit }, scores) {
    commit('highscores', scores)
  },
  /// PLAYERS
  async change_player({ commit, dispatch }) {
    commit('playerId', null)

    dispatch('join')
  },
  async socket_players({ commit }, players) {
    commit('players', players)
  },
  async socket_eventPlayerUpdated({ commit }, eventPlayer) {
    commit('event_player', eventPlayer)
  },
  async socket_teams({ commit }, teams) {
    commit('teams', teams)
  },
  async socket_playerConnected({ state }, player) {
    let idx = state.players.findIndex((u) => u.id == player.id)
    if (idx >= 0) {
      Vue.set(state.players, idx, player)
    } else {
      state.players.push(player)
    }
  },
  async socket_playerDisconnected({ state }, player) {
    let idx = state.players.findIndex((u) => u.id == player.id)
    if (idx >= 0) {
      Vue.set(state.players, idx, player)
    }
  },
  async socket_playerChanged({ state }, player) {
    let idx = state.players.findIndex((u) => u.id == player.id)
    if (idx >= 0) {
      Vue.set(state.players, idx, player)
    }
  },

  /// ANSWERS
  async socket_answers({ commit }, answers) {
    commit('answers', answers)
  },
  async socket_answerUpdated({ state }, answer) {
    let idx = state.answers.findIndex((a) => a.id == answer.id)
    if (idx >= 0) {
      Vue.set(state.answers, idx, answer)
    } else {
      state.answers.push(answer)
    }
  },
  async socket_answerDeleted({ state }, answerId) {
    let idx = state.answers.findIndex((a) => a.id == answerId)
    if (idx >= 0) {
      let answer = state.answers.splice(idx, 1)
      console.log("Answer deleted: ", answer)
    }
  },

  /// CHAT
  async send_chat_message(_, message) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('chat_message', message)
    }
  },
  async socket_chatMessage({ commit, state }, message) {
    commit('chat_message', message)
    
    if (message.senderId != state.playerId) {
      commit('unread_chat_messages', true)
      let teamIds = state.unread_teamchat_messages

      if (message && message.teamId) {
        teamIds.push(message.teamId);
      } else {
        teamIds.push(-1);
      }

      commit('unread_teamchat_messages', Array.from(new Set(teamIds)))
    }
  },
  async socket_eventChats({ commit }, chats) {
    commit('chat_messages', chats)
  },

  /// ADMIN METHODS
  async start_event() {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('start_event')
    }
  },
  async stop_event() {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('stop_event')
    }
  },
  async resume_event() {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('resume_event')
    }
  },
  async reset_event() {
    if (this._vm.$socket.connected) {
      let confirmed = await confirm('Hiermee wordt het hele event gereset, alle teams en antwoorden verwijderd. Weet je zeker dat je dit wilt doen?')

      if (confirmed)
        this._vm.$socket.client.emit('reset_event')
    }
  },
  async add_extra(_, extra) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('add_extra', extra)
    }
  },
  async delete_extra(_, extraId) {
    if (this._vm.$socket.connected && confirm('Are you sure you want to delete this badge?')) {
      this._vm.$socket.client.emit('delete_extra', extraId)
    }
  },
  async change_game_duration(_, duration) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('change_game_duration', duration)
    }
  },
  async unlock_gps_round(_, roundId) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('unlock_gps_round', roundId)
    }
  },
  async unlock_gps_task(_, taskId) {
    //TODO: For now we only check the distance in the App, maybe we should also check/validate this on the server
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('unlock_gps_task', taskId)
    }
  },
  async unlock_round(_, roundId) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('unlock_round', roundId)
    }
  },
  async toggle_round_visibility(_, roundId) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('toggle_round_visibility', roundId)
    }
  },
  async toggle_task_visibility(_, taskId) {
    if (this._vm.$socket.connected) {
      this._vm.$socket.client.emit('toggle_task_visibility', taskId)
    }
  },

  socket_connect({ commit }) {
    commit('connecting', false)
  },
  socket_disconnect({ commit }) {
    commit('connecting', false)
  },
}

export default (baseUrl) => {
  //set axios base url
  if (baseUrl && baseUrl != '') {
    axios.defaults.baseURL = baseUrl
  }

  return {
    namespaced: true,
    state: moduleState,
    getters: moduleGetters,
    mutations: moduleMutations,
    actions: moduleActions
  }
}
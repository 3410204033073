import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/axios'
import './plugins/vue-filters'

// BOOTSTRAP
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

//STYLING VAN DIT PROJECT (DE APP)
import './app.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

//FONT AWESOME
import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
library.add(far)
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

//SELECT
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

// LEAFLET
import { LMap, LTileLayer, LMarker, LCircle, LControl, LIcon, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle', LCircle);
Vue.component('l-icon', LIcon);
Vue.component('l-control', LControl);
Vue.component('l-popup', LPopup);
import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


//CUSTOM COMPONENTS
import Navigation from './components/Navigation'
import User from './components/User'
import Game from './components/Game'
import Event from './components/Event'
import Reseller from './components/Reseller'
import Upload from './components/Upload'
import OptionInput from './components/OptionInput'
import OptionColumnInput from './components/OptionColumnInput'
import MediaUpload from './components/MediaUpload'
import SelectRelatedResource from './components/SelectRelatedResource'
import Wysiwyg from './components/Wysiwyg'
import AnswersTable from './components/AnswersTable'
import LatLngSelect from './components/LatLngSelect'

Vue.component('navigation', Navigation)
Vue.component('user', User)
Vue.component('game', Game)
Vue.component('event', Event)
Vue.component('reseller', Reseller)
Vue.component('upload', Upload)
Vue.component('optioninput', OptionInput)
Vue.component('optioncolumninput', OptionColumnInput)
Vue.component('mediaupload', MediaUpload)
Vue.component('selectrelatedresource', SelectRelatedResource)
Vue.component('latlngselect', LatLngSelect)
Vue.component('wysiwyg', Wysiwyg)
Vue.component('answerstable', AnswersTable)

import MediaViewer from 'ub-lib/components/MediaViewer'
Vue.component('mediaviewer', MediaViewer)

import ChatItem from 'ub-lib/components/ChatItem.vue'
Vue.component('chatitem', ChatItem)

//socket.io
import { io } from 'socket.io-client'
import VueSocketIOExt from 'vue-socket.io-extended';
const socket = io("", { autoConnect: false })

if (process.env.NODE_ENV == 'development') {
  socket.onAny((event, ...args) => {
    console.log(event, args);
  });
}
socket.on("connect_error", (err) => {
  console.log(err)
})
Vue.use(VueSocketIOExt, socket, { store })

//VUE
Vue.config.productionTip = false

new Vue({
  router,
  store,
  sockets: {
  },
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import axios from 'axios'

//default for adding a new 'resource'
//TODO: Maybe think about more settings/properties or a better way
const defaultValues = {
  user: { 
    role: { placeholder: "", label: "Role" },
    email: { placeholder: "", label: "Email" }, 
    password: { placeholder: "", label: "Password" }, 
    firstname: { placeholder: "", label: "Firstname" }, 
    lastname: { placeholder: "", label: "Lastname" }, 
    resellerId: { placeholder: "Select reseller", label: "Reseller" } 
  },
  reseller: { 
    title: { placeholder: "", label: "Name/Title" } 
  },
  game: { 
    title: { placeholder: "", label: "Name/Title" } 
  },
  event: { 
    title: { placeholder: "", label: "Name/Title" }, 
    code: { placeholder: "", label: "Code" }, 
    resellerId: { placeholder: "Select reseller", label: "Reseller" }, 
    gameId: { placeholder: "Select game", label: "Game" } 
  },
  upload: {},
  feedback: {}
}

let createState = (resource) => {
  return {
    //store request so we can access it later and maybe cancel it
    request: null,

    //default values for adding a new 'resource'. 'clone' object by converting to json and back
    defaultValues: JSON.parse(JSON.stringify(defaultValues[resource])),
    //array of current loaded items
    items: [],

    //pagination
    currentPage: 1,
    totalPages: 1,
    itemsPerPage: 10,
    totalItems: 0,

    search: '',
    queryArgs: {},
  }
}
// eslint-disable-next-line no-unused-vars
let createGetters = (resource) => {
  return {
    item_index_by_item_id: (state) => {
      return state.items.reduce((map, item, index) => {
        map[item.id] = index
        return map
      }, {})
    },
    item_by_id: (state, getters) => (id) => {
      return state.items[getters.item_index_by_item_id[id]]
    },
  }
}
// eslint-disable-next-line no-unused-vars
let createMutations = (resource) => {
  return {
    request: (state, data) => { state.request = data },

    //set all items
    items: (state, data) => { state.items = data },
    //single item
    item: (state, data) => {
      let idx = state.items.findIndex((item) => item.id == data.id)
      if (idx >= 0) {
        Vue.set(state.items, idx, data)
      } else {
        state.items.push(data)
      }
    },

    //pagination
    currentPage: (state, data) => { state.currentPage = data },
    totalPages: (state, data) => { state.totalPages = data },
    itemsPerPage: (state, data) => { state.itemsPerPage = data },
    totalItems: (state, data) => { state.totalItems = data },

    search: (state, data) => { state.search = data },
    queryArgs: (state, data) => { state.queryArgs = data },
    
  }
}
let createActions = (resource) => {
  //generic resource actions
  let actions = {
    init: {
      root: true,
      handler({commit}) {
        commit('items', [])
        commit('currentPage', 1)
        commit('totalPages', 1)
        commit('totalItems', 0)
        commit('search', '')
        commit('queryArgs', {})
      }
    },
    async load_all({ commit, state }) {
      //cancel current request
      if (state.request) {
        state.request.cancel('Cancelled previous load-request')
      }
      //create new cancelToken
      commit('request', axios.CancelToken.source())

      //create route
      let route = `/api/${resource}?itemsPerPage=${state.itemsPerPage}&currentPage=${state.currentPage}`
      if (state.search) {
        route = `${route}&search=${state.search}`
      }

      for (const key in state.queryArgs) {
        if (Object.hasOwnProperty.call(state.queryArgs, key)) {
          route = `${route}&${key}=${state.queryArgs[key]}`
        }
      }

      await axios.get(route, { cancelToken: state.request.token })
        .then((response) => {
          commit('totalPages', parseInt(response.headers.totalpages))
          commit('totalItems', parseInt(response.headers.totalitems))

          commit('items', response.data)
        })
        .catch(() => {
          commit('items', [])
        })
      return state.items
    },
    async create({ commit, getters }, data) {
      let id = 0
      await axios.post(`/api/${resource}`, data)
        .then((response) => {
          commit('item', response.data)
          id = response.data.id
        })
        .catch((err) => { console.log(err) })
        
        return getters.item_by_id(id)
    },
    async load_single({ commit, getters }, id) {
      await axios.get(`/api/${resource}/${id}`)
        .then((response) => {
          commit('item', response.data)
        })
        .catch((err) => { console.log(err) })
        return getters.item_by_id(id)
    },
    async delete({ state }, id) {
      // TODO: Misschien een mooiere confirm-dialog toevoegen?
      let item = state.items.find((i) => i.id == id)
      if (confirm(`Are you sure you want to delete ${resource} ${item.title}? `)) {
        await axios.delete(`/api/${resource}/${id}`)
          .then(() => {
            let idx = state.items.findIndex((i) => i.id == id)
            if (idx > -1) {
              Vue.delete(state.items, idx)
            }
          })
          .catch((err) => { console.log(err) })
        }
    },
    async update({ commit, getters }, id) {
      await axios.put(`/api/${resource}/${id}`, getters.item_by_id(id))
        .then((response) => {
          commit('item', response.data)
        })
        .catch((err) => { console.log(err) })

      return getters.item_by_id(id)
    }
  }

  //custom actions for resource game
  if (resource == 'game') {
    actions.duplicate = async({ commit, getters }, id) => {
      await axios.post(`/api/${resource}/${id}/duplicate`)
        .then((response) => {
          commit('item', response.data)
          id = response.data.id
        })
        .catch((err) => { console.log(err) })
        
        return getters.item_by_id(id)
    }

    actions.create_round = async ({ commit, getters }, { gameId, round }) => {
      await axios.post(`/api/${resource}/${gameId}/round`, round)
        .then((response) => {
          commit('item', response.data)
        })
        .catch((err) => { console.log(err) })
        return getters.item_by_id(gameId)
    }
    actions.delete_round = async ({ commit }, { gameId, roundId }) => {
      await axios.delete(`/api/${resource}/${gameId}/round/${roundId}`)
        .then((response) => {
          commit('item', response.data)
        })
        .catch((err) => { console.log(err) })
    }

    actions.create_task = async ({ commit, getters }, { gameId, roundId, task }) => {
      await axios.post(`/api/${resource}/${gameId}/round/${roundId}/task`, task)
        .then((response) => {
          commit('item', response.data)
        })
        .catch((err) => { console.log(err) })
        return getters.item_by_id(gameId)
    }
    actions.delete_task = async ({ commit }, { gameId, roundId, taskId }) => {
      await axios.delete(`/api/${resource}/${gameId}/round/${roundId}/task/${taskId}`)
        .then((response) => {
          commit('item', response.data)
        })
        .catch((err) => { console.log(err) })
    }
  }
  else if (resource == 'event') {
    actions.generate_code = async () => {
      let code = false
      await axios.get(`/api/${resource}/generate_code`)
        .then((response) => {
          code = response.data
        })
        .catch((err) => { console.log(err) })

        return code
    }
  }

  //return actions object
  return actions
}

const ResourceModule = (resource) => {
  //create store objects
  let state = createState(resource)
  let actions = createActions(resource)
  let getters = createGetters(resource)
  let mutations = createMutations(resource)

  return {
    namespaced: true,

    state: () => (state),
    getters: getters,
    mutations: mutations,
    actions: actions
  }
}

export default ResourceModule
import Vue from 'vue'
import Vuex from 'vuex'
import AccessControl from 'accesscontrol'
import socket from 'ub-lib/sockets'
import resource from './resourcemodule'
import router from '../router'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    numPendingRequests: 0,
    initialized: false,
    currentUser: null,
    roles: new AccessControl(),
    gameOptions: [],
    roundOptions: [],
    taskOptions: [],
    eventOptions: [],
    resellerSkins: [],
    gameSkins: []
  },
  mutations: {
    numPendingRequests(state, numPendingRequests) {
      state.numPendingRequests = numPendingRequests
    },
    initialized(state, initialized) {
      state.initialized = initialized
    },
    currentUser(state, user) {
      state.currentUser = user
    },
    roles(state, roles) {
      state.roles = new AccessControl(roles)
    },
    options(state, options) {
      state.gameOptions = options.game
      state.roundOptions = options.round
      state.taskOptions = options.task
      state.eventOptions = options.event
      state.resellerSkins = options.resellerSkins
      state.gameSkins = options.gameSkins
    },
  },
  getters: {
    isLoading: (state) => { return state.numPendingRequests > 0 },
    isLoggedIn: (state) => {
      return state.currentUser && state.currentUser.role
    },
    is_dev_build: () => {
      return process.env.NODE_ENV == 'development'
    },
    permissions: (state) => (type, res) => {
      //todo: misschienm checken op ownership
      let p = state.roles.can(state.currentUser.role)[`${type}Any`](res) || state.roles.can(state.currentUser.role)[`${type}Own`](res)
      return p.granted
    },
    initial_marker_position: (state, getters) => {
      if (router.currentRoute.name == 'game') {
        var tasks = []
        var game = getters['game/item_by_id'](router.currentRoute.params.id)
        if (game && game.Rounds) {
          tasks = game.Rounds.map(r => r.Tasks.filter(t => t.Option.filter(o => o.key == 'start_type' && o.value == 'gps').length == 1).map(t => t)).flat()
        }

        if (tasks.length > 1) {
          let option = tasks[tasks.length - 2].Option.find(o => o.key == 'latlng')
          if (option && option.value && option.value.latlng) {
            return option.value.latlng
          }
        }

      }
      else if (state.currentUser && state.currentUser.Reseller && state.currentUser.Reseller.location) {
        return state.currentUser.Reseller.location.latlng
      }

      return [51.6006604, 5.6124116]
    }
  },
  actions: {
    //INITIALIZATION, called from navigation guard
    async init({ commit, dispatch, getters }) {
      //remove auth header
      delete Vue.axios.defaults.headers.common['Authorization']

      //clear data
      commit('initialized', false)
      commit('currentUser', null)

      //load roles for accesscontrol
      await dispatch('getRoles')
      await dispatch('getCurrentUser')
      if (getters.isLoggedIn) {
        await dispatch('getOptions')
      }

      commit('initialized', true)
    },
    /// AUTH
    async login({ dispatch }, { username, password }) {
      await Vue.axios.post('/api/login', { email: username, password: password }).then(async (loginResponse) => {
        if (loginResponse.status == 200) {
          //grab token from loginResponse
          let token = loginResponse.data.token

          //set token as Authorization header
          Vue.axios.defaults.headers.common['Authorization'] = `${token}`

          //decode jwt for userdata
          // let decoded = jwt.decode(token)

          dispatch('init')
        }
      })
        .catch(() => dispatch('init'))
    },
    async logout({ dispatch }) {
      //logout 
      await Vue.axios.post('/api/logout')
        .catch(err => console.log(err))
      await dispatch('init')
    },
    async getRoles({ commit }) {
      Vue.axios.get('/api/roles').then((response) => {
        commit('roles', response.data)
      })
        .catch((err) => console.log(err))
    },
    async getOptions({ commit }) {
      Vue.axios.get('/api/settings/options').then((response) => {
        commit('options', response.data)
      })
        .catch((err) => console.log(err))
    },
    async getCurrentUser({ commit }) {
      await Vue.axios.get('/api/me').then(async (response) => {
        //store user
        commit('currentUser', response.data)
      })
        .catch((err) => console.log(err))
    }
  },
  modules: {
    user: resource('user'),
    reseller: resource('reseller'),
    game: resource('game'),
    event: resource('event'),
    upload: resource('upload'),
    feedback: resource('feedback'),
    socket: socket()
  }
})

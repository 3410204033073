<template>
  <b-navbar toggleable="lg" type="">

    <b-navbar-brand to="/">
      <b-img v-if="!currentUser.Reseller" :src="require('../images/ub-games-logo.png')" class="logo" />
      <template v-else>{{currentUser.Reseller.title}}</template>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/home">Home</b-nav-item>
        <b-nav-item to="/game" v-if="permissions('read', 'game')">Games</b-nav-item>
        <b-nav-item to="/reseller" v-if="permissions('read', 'reseller')">Resellers</b-nav-item>
        <b-nav-item to="/event" v-if="permissions('read', 'event')">Events</b-nav-item>
        <b-nav-item to="/upload" v-if="permissions('read', 'upload')">Uploads</b-nav-item>
        <b-nav-item to="/user" v-if="permissions('read', 'user')">Users</b-nav-item>
        <b-nav-item to="/eventmedia" v-if="permissions('read', 'reseller')">Event Media</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/profile">{{ currentUser.firstname }}</b-nav-item>
        <b-nav-item to="/logout">Sign out</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Navigation',
  data: function() {
    return {
    }
  },
  computed: {
    ...mapState(['currentUser']),
    ...mapGetters(['isLoggedIn', 'permissions', 'isLoading', 'is_dev_build']),
  },
  watch: {
  },
  methods: {
    onCompleteReset() {
      this.$router.push('/logout')
    },
  }
}
</script>

<template>
  <div id="app">
    <template  v-if="initialized">
      <main>
        <router-view id="main-view"/>
      </main>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  data: function() {
    return {
    }
  },
  computed: {
    ...mapState(['initialized']),
  },
  methods: {
  }
}
</script>

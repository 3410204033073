<template>
  <div class="home">
    <navigation />
    <h1>EventMedia</h1>
    
    <!-- other resources -->
    <b-form id="filter-bar">
      <!-- additional search options for events -->
      <!-- <selectrelatedresource v-if="permissions('create', 'reseller') && (resource == 'event' || resource == 'upload')"
        label="Reseller" :key="`event-search-related-reseller`" resource="reseller"
        @input="selected => filterByResellerId(selected)" style="width: 250px" />
      <selectrelatedresource v-if="resource == 'event'" label="Game" :key="`event-search-related-game`" resource="game"
        @input="selected => filterByGameId(selected)" style="width: 250px" />

      <b-form-group label="Search">
        <b-input v-model="search" />
      </b-form-group> -->
      <b-form-group label="Status">
        <b-select v-model="status">
          <b-select-option value="">All</b-select-option>
          <b-select-option value="deleted">Deleted events</b-select-option>
          <b-select-option value="ended">Ended events</b-select-option>          
          <b-select-option value="active">Active events</b-select-option>
        </b-select>
      </b-form-group>
    </b-form>

    <b-table hover :items="tableData" :fields="cols" :tbody-tr-class="tableRowClass">
      
      <template #cell(status)="data">
        {{ data.item.event ? data.item.event.status : 'not found' }}
      </template>

      <template #cell(date)="data">
        <template v-if="data.item.event && data.item.event.start_date">
        {{ data.item.event.start_date }}
        </template>
      </template>

      <template #cell(age)="data">
        <template v-if="data.item.event && data.item.event.start_date">
          {{ calculateDaysPast(new Date(), new Date(data.item.event.start_date)) }}
        </template>
      </template>

      <template #cell(actions)="data">
        <b-button-group>
          <b-button :disabled="isLoading" v-if="permissions('read', 'event')" variant="link btn-icon" @click="downloadEventMedia(data.item.path)">
            <font-awesome-icon :icon="['far', 'download']" />
          </b-button>

          <b-button :disabled="isLoading" v-if="permissions('delete', 'event') && (data.item.event && data.item.event.status == 'ended') || !data.item.event" variant="link btn-icon" @click="deleteEventMedia(data.item.path)">
            <font-awesome-icon :icon="['far', 'trash']" />
          </b-button>
        </b-button-group>
      </template>

    </b-table>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex';
import { calculateDaysPast } from '../plugins/helpers';

export default {
  name: 'EventMedia',
  data: function() {
    return {
      status: '',
      eventMedia: [],
      cols: [
      { key: "code", label: "Code" },
      { key: "status", label: "Status"},
      { key: "date", label: "Date"},
      { key: "age", label: "Days past"},
      { key: "actions", label: ""}
      ]
    };
  },
  computed: {
    ...mapState(['roles', 'currentUser', 'eventOptions', 'gameOptions']),
    ...mapGetters(['isLoading', 'permissions']),

    tableData() {
      switch (this.status) {
        case '':
          return this.eventMedia
        case 'deleted':
          return this.eventMedia.filter(em => em.event == null)
        default:
          return this.eventMedia.filter(em => (em.event != null && em.event.status == this.status))
      }
    }
  },
  components: {
  },
  methods: {
    //TODO: Move this to ublib
    downloadEventMedia(code) {
      axios.get(`/api/event/media/${code}`, { responseType: 'arraybuffer' })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${code}.zip`); //or any other extension
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link)
        })
    },
    deleteEventMedia(code) {
      if (confirm(`Are you sure you want to delete all files uploaded to event with code ${code}? This does not remove the event itself, only the actual files that are uploaded.`))
      axios.delete(`/api/event/media/${code}`)
        .then(res => this.eventMedia = res.data)
    },
    calculateDaysPast(d1, d2) {
      return calculateDaysPast(d1, d2)
    },
    tableRowClass(item, type) {
      if (!item || type !== 'row') return
      let classes = []

      const today = new Date();
      if (item.event) {
        let eventDate = null
        // if (item.event.end_date) {
        //   eventDate = new Date(item.event.start_date)
        // } else if (item.event.start_date) {
          eventDate = new Date(item.event.start_date ?? 0)
        // }

        const daydiff = this.calculateDaysPast(today, eventDate)
        if (daydiff >= 30) {
          classes.push('table-warning')
        }

      } else {
        classes.push('table-danger')
      }

      // if (this.permissions('update', this.resource)) {
      //   classes.push('clickable')
      // }

      // switch (this.resource) {
      //   case 'user':
      //     if (item.status === 'inactive') classes.push('table-danger')
      //     break
      //   case 'game':
      //     if (item.duplicate) {
      //       classes.push('duplicate')
      //     } else {
      //       if (!this.permissions('create', this.resource))
      //         classes.splice(classes.indexOf('clickable'), 1)

      //       classes.push('template')
      //     }
      //     break
      // }

      return classes
    },
  },
  mounted() {
    axios.get(`/api/event/media`)
      .then(res => this.eventMedia = res.data)
  }
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import EventMedia from '../views/EventMedia.vue'
import Login from '../views/Login.vue'
import store from '../store'

Vue.use(VueRouter)

let createResourceRoutes = (resource) => {
  let routes = [
    {
      path: `/${resource}`,
      name: `${resource}s`,
      beforeEnter: (to, from, next) => {
        store.dispatch(`${resource}/load_all`)//.then(() => next())
        next()
      },
      component: () => import('../views/Archive.vue'),
      props: { resource: resource }
    },
    {
      path: `/${resource}/:id`,
      name: `${resource}`,
      beforeEnter: async (to, from, next) => {
        if (!isNaN(to.params.id)) {
          await store.dispatch(`${resource}/load_single`, to.params.id)//.then(() => next())
        }
        next()
      },
      component: () => import('../views/Single.vue'),
      props: { resource: resource }
    },
  ]
  if (resource == 'event') {
    routes.push(
      {
        path: `/${resource}/:id/manage`,
        beforeEnter: async (to, from, next) => {
          if (!isNaN(to.params.id)) {
            await store.dispatch(`${resource}/load_single`, to.params.id)//.then(() => next())
          }
          next()
        },
        component: () => import('../views/ManageEvent.vue'),
        props: { resource: resource },
        children: [
          {
            path: '',
            name: `Manage Event`,
            component: () => import('../views/manageevent/Dashboard.vue'),
          },
          {
            path: 'dashboard',
            name: 'EventDashboard',
            component: () => import('../views/manageevent/Dashboard.vue')
          },
          {
            path: 'players',
            name: 'EventPlayers',
            component: () => import('../views/manageevent/Players.vue')
          },
          {
            path: 'map',
            name: 'EventMap',
            component: () => import('../views/manageevent/Map.vue')
          },
          {
            path: 'teams',
            name: 'EventTeams',
            component: () => import('../views/manageevent/Teams.vue')
          },
          {
            path: 'answers',
            name: 'EventAnswers',
            component: () => import('../views/manageevent/Answers.vue')
          },
          {
            path: 'chat',
            name: 'EventChat',
            component: () => import('../views/manageevent/Chat')
          },
        ]
      },
    )
  }
  return routes
}

let routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Login
  },
  {
    path: '/profile',
    name: 'Profile',
    beforeEnter: (to, from, next) => {
      store.dispatch('getCurrentUser', to.params.id).then(() => next())
    },
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/eventmedia',
    name: 'Eventmedia',
    component: EventMedia
  },
]
routes = routes.concat(createResourceRoutes('user'))
routes = routes.concat(createResourceRoutes('reseller'))
routes = routes.concat(createResourceRoutes('game'))
routes = routes.concat(createResourceRoutes('event'))
routes = routes.concat(createResourceRoutes('upload'))

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

function navigationGuardUserCheck(to, from, next) {
  if (to.matched.some(route => route.name == "Login") && !store.getters.isLoggedIn) {
    next()
  } else if (to.matched.some(route => route.name == "Login") && store.getters.isLoggedIn) {
    next({ name: 'Home' })
  } else if (!to.matched.some(route => route.name == "Login") && !store.getters.isLoggedIn) {
    next({ name: 'Login' })
  } else {
    next()
  }
}

router.beforeEach((to, from, next) => {
  if (!store.state.initialized) {
    store.dispatch('init', null, { root: true }).then(() => {
      navigationGuardUserCheck(to, from, next)
    })
  } else {
    navigationGuardUserCheck(to, from, next)
  }
})

export default router

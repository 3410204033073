<template>
  <div class="user">
    <div id="top">
      <h1>{{item.firstname}} {{item.lastname}}</h1>
       <b-button variant="primary" @click="$emit('save')"><font-awesome-icon :icon="['far', 'save']"/>Save</b-button>
    </div>
    <div v-if="item">
      <b-row>
        <b-col sm="6">
          <b-row>
            <b-col sm="6">
              <b-form-group label="First name">
                <b-form-input v-model="item.firstname" :disabled="isLoading" type="text" placeholder="" />
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group label="Last name">
                <b-form-input v-model="item.lastname" :disabled="isLoading" type="text" placeholder="" />
              </b-form-group>
            </b-col>
          </b-row>

          <b-form-group label="Email address">
            <b-form-input v-model="item.email" :disabled="isLoading" type="text" placeholder="" />
          </b-form-group>

          <b-form-group label="Role">
            <b-select v-model="item.role" :options="[currentUser.role].concat(roles.getInheritedRolesOf(currentUser.role))" required />
          </b-form-group>

          <b-form-group label="Status">
            <b-form-radio v-model="item.status" name="Active" value="active">Active</b-form-radio>
            <b-form-radio v-model="item.status" name="Inactive" value="inactive">Inactive</b-form-radio>
          </b-form-group>
          
        </b-col>

        <b-col sm="6">
          <!-- TODO: Zorgen dat het werkt!!!!1 -->
          <b-form-group label="Reseller" v-if="!currentUser.Reseller && item.Reseller">
            <b-form-input v-model="item.Reseller.title" disabled type="text" placeholder="" />
          </b-form-group>
        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'User',
  data: function() {
    return {
      
    };
  },
  props: {
    item: Object,
    resource: String
  },
  computed: {
    ...mapGetters(['isLoading', 'permissions']),
    ...mapState(['roles', 'currentUser']),
  },
  methods: {
  },
}
</script>

<template>
  <div class="reseller">
    <div v-if="item">
      <div id="top">
        <h1>{{item.title}}</h1>
        <b-button variant="primary" @click="$emit('save')"><font-awesome-icon :icon="['far', 'save']"/>Save</b-button>
      </div>

      <b-tabs>

        <!-- Details -->
        <b-tab title="Details" active>
          <b-row>
            <b-col cols=6>
              <!-- TODO: Misschien een 'fixed' formulier voor maken met vaste velden ipv door de velden te loopen
              Of misschien kunnen we iets bedenken om de 'default-values' objecten te gebruiken uit de store -->
              <b-form-group label="Reseller name">
                <b-form-input :disabled="isLoading" v-model="item.title" type="text"></b-form-input>
              </b-form-group>

              <b-form-group label="Desciption">
                <wysiwyg v-model="item.description" />
              </b-form-group>

              <mediaupload key="ResellerLogo" :upload="item.Logo" field="logoId" label="Logo" :item="item" @change="$emit('save')" />

              <b-form-group label="Skin">
                <!-- <b-form-input v-model="item.skin" ></b-form-input> -->
                <b-form-select v-model="item.skin" :options="resellerSkins"></b-form-select>
              </b-form-group>
              
              <b-form-group label="Location">
                <latlngselect v-model="item.location" />
              </b-form-group>

            </b-col>
          </b-row>
        </b-tab>

        <!-- Games -->
        <b-tab title="Games">
          <b-table v-if="item.Games && item.Games.length > 0" striped hover :items="item.Games" :fields="['title', 'actions']" :busy="isLoading">
            <template #cell(title)="data">
              <!-- add duplicate or template icon for game -->
              <font-awesome-icon class="mr-2" v-if="!data.item.duplicate" :icon="['far', 'lock']"/>
              {{ data.item.title }}<span v-if="data.item.duplicate">: {{ data.item.subtitle }}</span>
            </template>

            <!-- Actions -->
            <template #cell(actions)="data">
              <b-button-group>
                <b-button v-if="permissions('create', resource)" variant="primary btn-icon" @click="item.Games.splice(data.index, 1)"><font-awesome-icon :icon="['far', 'trash']"/></b-button>
              </b-button-group>
            </template>
          </b-table>

          <b-alert v-else variant="danger" show>No games added</b-alert>

          <!-- Add game -->
          <div id="add-game">
            <selectrelatedresource resource="game" v-model="selectedGame" label="Select game" placeholder="Select game" />
            <b-button v-if="permissions('create', resource)" :disabled="selectedGame == null" variant="primary" @click="item.Games.push({ id: selectedGame.id, title: selectedGame.title }); selectedGame = null"><font-awesome-icon :icon="['far', 'plus']"/> Add game</b-button>
          </div>
        </b-tab>

        <!-- Users -->
        <b-tab title="Users">
          <b-table v-if="item.Users && item.Users.length > 0" striped :items="item.Users" :busy="isLoading" />

          <b-alert v-else variant="danger" show>No users added</b-alert>
        </b-tab>

        <!-- Privacy -->
        <b-tab title="Privacy Policy">
          <wysiwyg v-model="item.privacy_policy" />
        </b-tab>

        <!-- Terms -->
        <b-tab title="Terms & Conditions">
          <wysiwyg v-model="item.terms_and_conditions" />
        </b-tab>

      </b-tabs>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'Reseller',
  data: function() {
    return {
      selectedGame: null
    };
  },
  props: {
    item: Object,
    resource: String
  },
  computed: {
    ...mapGetters(['isLoading', 'permissions']),
    ...mapState(['resellerSkins'])
  },
  methods: {
  },
}
</script>

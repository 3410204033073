<template>
  <div class="login">
    <b-form @submit.prevent="login({ username: user, password: password })">
      <b-img :src="require('../images/ub-games-logo.png')" class="logo" />

      <b-form-group label="Username" label-for="username">
        <b-form-select
          v-if="is_dev_build"
          id="username"
          v-model="user"
          :options="users"
          @change="password = user.split('@')[0]"
        ></b-form-select>
        <b-form-input v-else id="username" v-model="user" />
      </b-form-group>

      <b-form-group label="Password" label-for="password">
        <b-form-input id="password" type="password" v-model="password" />
      </b-form-group>

      <div class="form-buttons">
        <b-button variant="link">Lost password</b-button>
        <b-button type="submit" variant="primary">Login</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  data: function () {
    return {
      user: "",
      password: "",
      users: [
        "superadmin@flyingkiwi.nl",
        "admin@flyingkiwi.nl",

        "reseller@flyingkiwi.nl",
        "eventorganiser@flyingkiwi.nl",
        "eventmanager@flyingkiwi.nl",

        "reseller@uitjesbazen.nl",
        "eventorganiser@uitjesbazen.nl",
        "eventmanager@uitjesbazen.nl",
      ],
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "is_dev_build"]),
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue) this.$router.push({ name: "Home" });
    },
  },
  components: {},
  async mounted() {
    if (this.isLoggedIn) {
      if (this.$router.currentRoute.name == "Logout") {
        await this.$store.dispatch("logout");
        this.$router.push("/");
      } else {
        this.$router.push({ name: "Home" });
      }
    }
  },
  methods: {
    ...mapActions(["login"]),
  },
};
</script>

import Vue from 'vue'
import moment from 'moment'

moment.locale('en')

Vue.filter('formatChatDate', function (value) {
  if (value) {
    return moment(String(value)).format('dddd DD MMMM YYYY')
  }
})
Vue.filter('formatChatTime', function (value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('dddd DD MMMM YYYY HH:mm:ss')
  }
})
<template>
  <div class="media-upload">
    <b-form-group :label="label">
      <template v-if="upload">
        <!-- Image Preview. For sizing options see https://www.npmjs.com/package/express-sharp#client-integration -->
        <mediaviewer :media="upload" />

        <!-- Remove image button -->
        <!-- TODO: Remove item werkend maken -->
        <b-button
          variant="primary"
          class="remove-media btn-icon"
          @click="removeItem"
          ><font-awesome-icon :icon="['far', 'times']"
        /></b-button>
      </template>

      <template v-else>
        <!-- Sppend extra button for uploadlibrary -->
        <b-button
          variant="primary"
          v-b-modal="'modal-media-library-' + $vnode.key"
          class="from-library btn-icon"
          ><font-awesome-icon :icon="['far', 'book-open']" />&nbsp;Select from
          library</b-button
        >

        <!-- File Input -->
        <b-form-file
          :id="`media-upload-${$vnode.key}`"
          :accept="accept"
          v-model="file"
          placeholder="Select from computer"
          drop-placeholder="Drop it!"
        ></b-form-file>
      </template>
    </b-form-group>

    <!-- TODO: Modal nog beter stylen -->
    <!-- TODO: Misschien apart component van maken met meer opties? Multiselect? Sort? Filter by 'relationship' misschien? -->
    <b-modal
      :id="'modal-media-library-' + $vnode.key"
      size="xl"
      @ok="setSelectedupload"
    >
      <selectrelatedresource
        v-if="permissions('create', 'reseller')"
        label="Reseller"
        :key="`event-search-related-reseller`"
        resource="reseller"
        @input="(selected) => filterByResellerId(selected)"
        style="width: 250px"
      />

      <b-row cols="5" cols-md="5" cols-lg="10" cols-xl="10">
        <b-col v-for="item in items" :key="`media-${item.id}`">
          <mediaviewer
            class="media-library-item"
            :class="{ selected: selected && selected.id == item.id }"
            :media="item"
            width="400"
            :crop="true"
            @click="selectMedia(item)"
          />
        </b-col>
      </b-row>

      <b-pagination
        v-if="totalPages > 1"
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="itemsPerPage"
        align="center"
      ></b-pagination>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Resource from "../mixins/resource";

export default {
  name: "MediaUpload",
  data: function () {
    return {
      file: null,
      fields: [
        { key: "preview", label: "Preview" },
        { key: "filename", label: "Filename" },
      ],
      resource: "upload",
      selected: [],
    };
  },
  mixins: [Resource],
  props: {
    upload: Object,
    item: Object,
    field: String,
    label: String,
    accept: String,
  },
  computed: {
    ...mapGetters(["isLoading", "permissions"]),
  },
  mounted() {
    //remove any filters
    const args = Object.assign({}, this.queryArgs);
    delete args["resellerId"];
    this.queryArgs = args;

    this.itemsPerPage = 25;
    this.refresh();
  },
  watch: {
    file() {
      this.updateItem();
    },
  },
  methods: {
    filterByResellerId(selected) {
      if (selected) {
        this.$set(this.queryArgs, "resellerId", selected.id);
      } else {
        const args = Object.assign({}, this.queryArgs);
        delete args["resellerId"];
        this.queryArgs = args;
      }
    },

    deleteItem() {
      this.$store
        .dispatch(`upload/delete`, this.$route.params.id)
        .then(() => {});
    },
    updateItem() {
      let form = new FormData();
      form.append("file", this.file);

      // TODO: Image eerst deleten ivm diskspace?
      this.$store.dispatch(`upload/create`, form).then((upload) => {
        this.item[this.field] = upload.id;
        this.$emit("change");
      });
    },
    removeItem() {
      this.item[this.field] = null;
      this.$emit("change");
    },
    selectMedia(item) {
      if (this.selected && this.selected.id == item.id) {
        this.selected = null;
      } else {
        this.selected = item;
      }
    },
    // onuploadSelected(items) {
    //   this.selected = items
    // },
    setSelectedupload() {
      if (this.selected) {
        this.item[this.field] = this.selected.id;
        this.$emit("change");
      }
      // if (this.selected.length == 1) {
      //   this.item[this.field] = this.selected[0].id
      //   this.$emit('change')
      // }
    },
  },
};
</script>

<template>
  <div v-if="editor">
    <div class="wysiwys-editor">
      <div :class="editor.isActive('heading', { level: 2 }) ? 'active' : ''" @click="editor.chain().focus().toggleHeading({ level: 2 }).run()">
        <font-awesome-icon :icon="['far', 'h2']"></font-awesome-icon>
      </div>

      <div :class="editor.isActive('heading', { level: 3 }) ? 'active' : ''" @click="editor.chain().focus().toggleHeading({ level: 3 }).run()">
        <font-awesome-icon :icon="['far', 'h3']"></font-awesome-icon>
      </div>

      <div :class="editor.isActive('bold') ? 'active' : ''" @click="editor.chain().focus().toggleBold().run()">
        <font-awesome-icon :icon="['far', 'bold']"></font-awesome-icon>
      </div>
      <div :class="editor.isActive('italic') ? 'active' : ''" @click="editor.chain().focus().toggleItalic().run()">
        <font-awesome-icon :icon="['far', 'italic']"></font-awesome-icon>
      </div>
      <div :class="editor.isActive('bulletList') ? 'active' : ''" @click="editor.chain().focus().toggleBulletList().run()">
        <font-awesome-icon :icon="['far', 'list']"></font-awesome-icon>
      </div>
      <div :class="editor.isActive('orderedList') ? 'active' : ''" @click="editor.chain().focus().toggleOrderedList().run()">
        <font-awesome-icon :icon="['far', 'list-ol']"></font-awesome-icon>
      </div>
    </div>
    <editor-content :editor="editor" />
  </div>
</template>

<script>
// TODO: https://tiptap.dev/guide/menus

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'

export default {
  name: 'Wysiwyg',
  data() {
    return {
      editor: null,
    }
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },

  components: { EditorContent },
}
</script>
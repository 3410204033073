<template>
  <l-map @click="setLocation" ref="map" :style="{ width: '100%', height: '400px' }" @ready="mapReady">
    <l-tile-layer :url="maps.url"></l-tile-layer>

    <l-marker v-if="value && value.latlng" :lat-lng="value.latlng"></l-marker>
    <l-circle v-if="value && value.latlng" :lat-lng="value.latlng" :radius="value.radius" :color="'blue'" :weight="0.1" />

    <l-control position="bottomleft" v-if="value">
      <b-input-group size="sm">
        <template #prepend>
          <b-input-group-text>Radius</b-input-group-text>
        </template>
        <b-form-input type="number" v-model.number="value.radius" title="Radius in meters" placeholder="Radius in meters"></b-form-input>
        <template #append>
          <b-input-group-text>meters</b-input-group-text>
        </template>
      </b-input-group>
    </l-control>
  </l-map>
</template>

<script>
import { mapGetters } from "vuex"
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/assets/css/leaflet.css'

export default {
  name: 'LatLngSelect',
  data: function() {
    return {
      maps: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      },
    }
  },
  props: {
    value: Object,
  },
  computed: {
    ...mapGetters(['initial_marker_position'])
  },
  mounted() {
    if (this.value == undefined || this.value == null) this.$emit('input', {
      latlng: this.initial_marker_position,
      radius: 0
    })
  }, 
  methods: {
    setLocation(event) {
      let selected = {
        latlng: event.latlng,
        radius: this.value ? this.value.radius : 0
      }

      this.$emit('input', selected)
    },
    mapReady() {
      this.$nextTick(() => {
        this.$refs.map.mapObject.setView(this.value.latlng, 20)

        // https://github.com/smeijer/leaflet-geosearch
        const provider = new OpenStreetMapProvider()
        const searchControl = new GeoSearchControl({
          provider: provider,
          autoComplete: true,
          autoCompleteDelay: 500,
          style: 'bar',
          showMarker: false,
          retainZoomLevel: true
        })
        this.$refs.map.mapObject.addControl(searchControl)

      })
    },

    onChange(selected) {
      this.selectedsearchresult = selected
      if (this.selectedsearchresult) 
        this.$refs.map.mapObject.setView([this.selectedsearchresult.y, this.selectedsearchresult.x], this.$refs.map.mapObject.zoom)
    },

    async fetchOptions(search, loading) {
      loading(true)
      
      this.searchresults = await this.provider.search({ query: search })

      loading(false)
    }
  }
}
</script>
exports.getTimeDiff = (starttime) => {
  let diff = {}
  diff.miliseconds = Date.now() - starttime
  diff.seconds = diff.miliseconds / 1000
  diff.minutes = diff.seconds / 60
  
  return diff
}
exports.formatSecondsToTime = (totalseconds) => {
  let minutes = Math.floor(totalseconds / 60)
  let seconds = Math.floor(totalseconds % 60)
  
  return minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0')
}
exports.getKeyValueData = (data, key, defaultValue) => {
  let value = defaultValue
  if (data) {
    let keyValue = data.find(o => o.key == key)
    if (keyValue) {
      value = keyValue.value
    }
  }
  
  return value
}
<template>
  <!-- TODO: Conditional logic / toggle values verder uitwerken -->
  <!-- TODO: Iets bedenken om bij een MultipleChoice Option Answer een select of dropdown te maken van de beschikbare values in de andere option -->
  <b-form-group :key="item.id + '-' + option.key" :label="option.label" v-if="item.Option && visible">
    <template v-if="option.key == 'ar_object'">
      <b-form-select :requried="option.required" :disabled="isLoading" v-if="arAllowed" :options="arObjects" v-model="value" @change="onValueChanged" />
      <div v-else>No AR Objects available</div>
    </template>

    <template v-else>
      <!-- DROPDOWN -->
      <b-form-select :requried="option.required" :disabled="isLoading" v-if="option.type == 'select'" :options="option.options" v-model="value" @change="onValueChanged" />
      <!-- CHECKBOXES -->
      <b-form-checkbox :requried="option.required" :disabled="isLoading" v-else-if="option.type == 'checkbox'" :value="option.value" v-model="value" @change="onValueChanged">{{option.label}}</b-form-checkbox>
      <!-- CUSTOM ARRAY DATA (E.G. MULTIPLECOICE) -->
      <b-form-tags v-else-if="option.type == 'array'" v-model="value" separator=",;" placeholder="Enter new tags separated by comma or semicolon" no-add-on-enter />
      <!-- CUSTOM SELECT FOR ROUND (WE CAN EXPECT 'item' TO BE A ROUND) -->
      <template v-else-if="option.type == 'select_round'">
        <b-form-select v-model.number="value" :options="game_by_id(item.gameId).Rounds.map(r => { return { value: r.id, text: r.title} })" />
      </template>
      <latlngselect v-else-if="option.type == 'latlng'" v-model="value" />
      <!-- OTHER TYPES SHOULD BE A HTML INPUT TYPE -->
      <b-input-group v-else>
        <b-form-input :requried="option.required" :disabled="isLoading" :type="option.type" :placeholder="option.key" v-model="value" @blur="onValueChanged" />
        <template #append v-if="option.append">
          <b-input-group-text>{{option.append}}</b-input-group-text>
        </template>
      </b-input-group>
    </template>

  </b-form-group>
</template>

<script>
import { mapGetters, mapState} from 'vuex'
import Vue from 'vue'

export default {
  name: 'OptionInput',
  data: function() {
    return {
      allowedArResellerIds: [4],
      arObjects: [
        { value: "Amerika", text: "Amerika" },
        { value: "Eritrea", text: "Eritrea" },
        { value: "India", text: "India" },
        { value: "Marokko", text: "Marokko" },
        { value: "Oekraine", text: "Oekraine" },
        { value: "Suriname", text: "Suriname" },
        { value: "Taiwan", text: "Taiwan" },
        { value: "Zuid-Amerika", text: "Zuid-Amerika" },
      ]
    }
  },
  props: {
    item: Object,
    option: Object,
  },
  watch: {
    item() {
      //set default values if needed
      if (this.option.defaultValue && (this.value == null || this.value == undefined)) {
        this.value = this.option.defaultValue
      }
    }
  },
  computed: {
    ...mapGetters('game', {'game_by_id': 'item_by_id'}),

    arAllowed() {
      return this.currentUser.Reseller == null || this.allowedArResellerIds.includes(this.currentUser.Reseller.id)
    },

    value: {
      // getter
      get() {
        //check if item options-array is available
        if (!this.item.Option) return ""

        let val = this.item.Option.find((o) => o.key == this.option.key)
        if (val) return val.value
        return null
      },
      // setter
      set (newValue) {
        //check if item options-array is available
        if (!this.item.Option) return

        let idx = this.item.Option.findIndex((o) => o.key == this.option.key)
        if (idx >= 0) {
          Vue.set(this.item.Option[idx], 'value', newValue)
        } else {
          this.item.Option.push({ key: this.option.key, value: newValue })
        }
      },
    },
    
    visible: {
      get() {
        //check if option is toggled
        if (this.option.toggle) {
          //check if item options-array is available
          if (!this.item.Option) return false

          if (Array.isArray(this.option.toggle_value)) {
            return this.item.Option.find((o) => o.key == this.option.toggle && this.option.toggle_value.includes(o.value))
          } else {
            return this.item.Option.find((o) => o.key == this.option.toggle && o.value == this.option.toggle_value)
          }
        }

        //options without toggle are always visible
        return true
      }
    },
    ...mapState(['currentUser']),
    ...mapGetters(['isLoading', 'permissions']),
  },
  methods: {
    onValueChanged() {
      this.$emit('change', this.item)
    },
    deleteElement(ev, idx) {
      if (this.value[idx] == '') {
        ev.preventDefault()
        this.value.splice(idx, 1)
      }
    },   
  },
  updated() {
    if (this.option.type == 'array' && !Array.isArray(this.value)) {
      this.value = [""]
    }
  },
  mounted() {
    if (this.option.defaultValue && (this.value == null || this.value == undefined)) {
      this.value = this.option.defaultValue
    }
  }
}
</script>

<template>
  <b-form-group :label="resource">
    <v-select
      :options="items.map((itm) => Object.assign(itm, { label: itm.title }))"
      :value="value"
      @input="onChange"
      :required="required"
      @search="fetchOptions"
      :filterable="false"
      :loading="isLoading"
    >
      <template #option="item">
        {{ item.title }}
        <template v-if="item.subtitle">({{ item.subtitle }})</template>
        <template v-if="item.Reseller && item.Reseller.title"
          >({{ item.Reseller.title }})</template
        >
      </template>

      <template #selected-option="item">
        {{ item.title }}
        <template v-if="item.subtitle">({{ item.subtitle }})</template>
        <template v-if="item.Reseller && item.Reseller.title"
          >({{ item.Reseller.title }})</template
        >
      </template>

      <!-- <li slot="list-footer" class="pagination" v-if="totalPages > 1">
        <b-button :disabled="currentPage == 1" @click="currentPage--">Prev</b-button>
        <b-button :disabled="currentPage == totalItems" @click="currentPage++">Next</b-button>
        <b-pagination v-if="totalPages > 1" v-model="currentPage" :total-rows="totalItems" :per-page="itemsPerPage" align="center"></b-pagination>
      </li> -->
    </v-select>

    <!-- <b-select :value="value" @change="onChange" :required="required">
      <template #first>
        <b-form-select-option :value="undefined" disabled :key="`${resource}-select-option-null`">{{ placeholder }}</b-form-select-option>
      </template>

      <b-form-select-option v-for="item in items" :value="item" :key="`${resource}-select-option-${item.id}`" :selected="value && value.id == item.id">
        {{ item.title }} 
        <template v-if="item.subtitle">({{ item.subtitle }})</template>
        <template v-if="item.Reseller && item.Reseller.title">({{ item.Reseller.title }})</template>
      </b-form-select-option>
    </b-select> -->
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
import Resource from "../mixins/resource";
export default {
  name: "SelectRelatedResource",
  data: function () {
    return {};
  },
  props: {
    required: Boolean,
    label: String,
    placeholder: String,
    resource: String,
    value: Object,
  },
  mixins: [Resource],
  components: {},
  computed: {
    ...mapGetters(["isLoading", "permissions"]),
  },
  methods: {
    onChange(selected) {
      this.$emit("input", selected);
    },
    fetchOptions(search) {
      this.search = search;
    },
  },
  mounted() {
    this.itemsPerPage = 99999999;
    this.currentPage = 1;
    this.refresh();
  },
};
</script>

<template>
  <!-- TODO: Conditional logic / toggle values verder uitwerken -->
  <!-- TODO: Iets bedenken om bij een MultipleChoice Option Answer een select of dropdown te maken van de beschikbare values in de andere option -->

  <b-form-group
    :key="item.id + '-' + option.key"
    :label="option.label"
    v-if="visible"
  >
    <!-- DROPDOWN -->
    <b-form-select
      :requried="option.required"
      :disabled="is_disabled || isDisabled(option)"
      v-if="option.type == 'select'"
      :options="option.options"
      v-model="value"
      @change="onValueChanged"
    />

    <!-- CHECKBOXES -->
    <b-form-checkbox
      :requried="option.required"
      :disabled="is_disabled"
      v-else-if="option.type == 'checkbox'"
      :value="option.value"
      v-model="value"
      @change="onValueChanged"
      >{{ option.label }}</b-form-checkbox
    >

    <!-- CUSTOM ARRAY DATA (E.G. MULTIPLECOICE) -->
    <template v-else-if="option.type == 'array'">
      <b-input-group>
        <template v-for="(val, idx) in value">
          <b-form-input
            :key="`option-${option.key}-${idx}`"
            :disabled="is_disabled"
            v-model="value[idx]"
            @blur="onValueChanged"
            :name="`option-${option.key}-${idx}`"
            @keydown.backspace="deleteElement($event, idx)"
          />
        </template>
        <b-input-group-append>
          <b-button @click="value.push('')">
            <font-awesome-icon :icon="['far', 'plus']" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </template>

    <!-- CUSTOM SELECT FOR ROUND (WE CAN EXPECT 'item' TO BE A ROUND) -->
    <template v-else-if="option.type == 'select_round'">
      <b-form-select
        v-model.number="value"
        :options="
          game_by_id(item.gameId).Rounds.map((r) => {
            return { value: r.id, text: r.title };
          })
        "
      />
    </template>

    <template v-else-if="option.type == 'datetime'">
      <b-input-group>
        <b-form-datepicker
          :locale="`en-US`"
          :requried="option.required"
          :disabled="is_disabled"
          :type="'date'"
          v-model="date"
          @blur="onValueChanged"
        />
        <b-form-input
          :requried="option.required"
          :disabled="is_disabled"
          :type="'time'"
          v-model="time"
          @blur="onValueChanged"
        />
      </b-input-group>
    </template>

    <!-- OTHER TYPES SHOULD BE A HTML INPUT TYPE -->
    <b-input-group v-else>
      <b-form-input
        :requried="option.required"
        :disabled="is_disabled"
        :type="option.type"
        :placeholder="option.key"
        v-model="value"
        @blur="onValueChanged"
      />
      <template #append v-if="option.append">
        <b-input-group-text>{{ option.append }}</b-input-group-text>
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "OptionColumnInput",
  data: function () {
    return {
      forced: false,
    };
  },
  props: {
    item: Object,
    option: Object,
    disabled: Boolean,
  },
  watch: {
    item: {
      deep: true,
      handler() {
        //set default values if needed
        if (this.option.defaultValue && this.value == "") {
          this.value = this.option.defaultValue;
        }

        this.forced = false;
        if (this.option.forceValue) {
          if (Array.isArray(this.option.forceValue)) {
            this.option.forceValue.forEach((f) => {
              if (this.item[f.key] == f.value) {
                this.value = f.force_to;
                this.forced = true;
              }
            });
          }
        }

        this.$forceUpdate();
      },
    },
  },
  computed: {
    ...mapGetters("game", { game_by_id: "item_by_id" }),
    ...mapState(["currentUser"]),

    value: {
      // getter
      get() {
        return this.item[this.option.key];
      },
      // setter
      set(newValue) {
        Vue.set(this.item, this.option.key, newValue);
      },
    },

    date: {
      get() {
        return this.formatDate(this.value);
      },
      set(val) {
        this.value = new Date(`${val} ${this.time}`);
      },
    },
    time: {
      get() {
        return this.formatTime(this.value);
      },
      set(val) {
        this.value = new Date(`${this.date} ${val}`);
      },
    },

    visible: {
      get() {
        //check if option is toggled
        if (this.option.toggle) {
          if (Array.isArray(this.option.toggle_value)) {
            return this.option.toggle_value.includes(
              this.item[this.option.toggle]
            );
          } else {
            return this.item[this.option.toggle] == this.option.toggle_value;
          }
        }

        //options without toggle are always visible
        return true;
      },
    },

    is_disabled() {
      return this.isLoading || this.forced || this.disabled;
    },

    ...mapGetters(["isLoading", "permissions"]),
  },
  methods: {
    onValueChanged() {
      this.$emit("change", this.item);
    },
    deleteElement(ev, idx) {
      if (this.value[idx] == "") {
        ev.preventDefault();
        this.value.splice(idx, 1);
      }
    },
    isDisabled(item) {
      return this.currentUser.role == "reseller" && item.key == "game_type";
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    formatTime(date) {
      var d = new Date(date),
        hours = "" + d.getHours(),
        minutes = "" + d.getMinutes();

      if (hours.length < 2) hours = "0" + hours;
      if (minutes.length < 2) minutes = "0" + minutes;

      return [hours, minutes].join(":");
    },
  },
  updated() {
    if (this.option.type == "array" && !Array.isArray(this.value)) {
      this.value = [""];
    }
  },
  mounted() {
    if (
      this.option.type == "datetime" &&
      (this.item[this.option.key] == undefined ||
        this.item[this.option.key] == "undefined")
    ) {
      Vue.set(this.item, this.option.key, new Date(this.item[this.option.key]));
      let date = new Date();
      date.setHours(date.getHours() + 1);
      if (isNaN(this.value)) this.value = date.valueOf();
    }
  },
};
</script>

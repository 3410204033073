export default {
  data: function() {
    return {
      newItem: { },
    }
  },
  computed: {
    defaultValues() { return this.$store.state[this.resource].defaultValues },
    items() { return this.$store.state[this.resource].items },
    
    totalItems: {  get() { return this.$store.state[this.resource].totalItems }, },
    totalPages: {  get() { return this.$store.state[this.resource].totalPages }, },
    itemsPerPage: { 
      get() { return this.$store.state[this.resource].itemsPerPage },
      set(value) { return this.$store.commit(`${this.resource}/itemsPerPage`, value) }
    },
    currentPage: { 
      get() { return this.$store.state[this.resource].currentPage },
      set(value) { return this.$store.commit(`${this.resource}/currentPage`, value) }
    },

    search: {
      get() { return this.$store.state[this.resource].search },
      set(value) { return this.$store.commit(`${this.resource}/search`, value) }
    },

    queryArgs: {
      get() { return this.$store.state[this.resource].queryArgs },
      set(value) { return this.$store.commit(`${this.resource}/queryArgs`, value) }
    }
  },
  watch: {
    itemsPerPage() { 
      this.$store.commit(`${this.resource}/currentPage`, 1)
      this.refresh()
    },
    currentPage() { this.refresh()},
    search() {
      this.$store.commit(`${this.resource}/currentPage`, 1)
      this.refresh()
    },
    queryArgs: {
      deep: true,
      handler() {
        this.$store.commit(`${this.resource}/currentPage`, 1)
        this.refresh()
      }
    },
  },

  methods: {
    refresh() {
      this.$store.dispatch(`${this.resource}/load_all`) 
    },
    deleteItem(id) {
      this.$store.dispatch(`${this.resource}/delete`, id)
    },
    async createItem() {
      await this.$store.dispatch(`${this.resource}/create`, this.newItem).then((result) =>  {
        if (result && result.id) {
          this.$router.push(`/${this.resource}/${result.id}`)
          //reset values
          this.newItem = {}
        }
      })
      return false
    },
  },
}